import React from 'react';
import { HeroMenu, ButtonText, Container } from '@dylanlewis/shared';
import { DataQuery } from '../../../shared/helpers/DataQuery';
import { AuctionsExhibitionsList } from './AuctionsExhibitionsList';
import gql from 'graphql-tag';

export class PageExhibitionsAuctions extends React.Component {
  state = {
    orderType: 'All',
  };

  render() {
    const { page, parent } = this.props;
    console.log('page', page);
    return (
      page.acf.related_items && (
        <DataQuery
          query={AUCTIONS_AND_EXHIBITIONS_QUERY}
          variables={{
            postType: page.acf.related_items,
            per_page: 50,
            order: 'desc',
          }}
          render={data => {
            const sortByMenu = generateMenu(data.viewItems);

            const filteredData = data.viewItems.filter(item => {
              if (this.state.orderType === 'All') {
                return item;
              }
              return (
                item.auction_exhibition_type[0].name === this.state.orderType
              );
            });

            return (
              <React.Fragment>
                <Container>
                  <HeroMenu
                    title={page.title}
                    renderMenu={sortByMenu.map((f, index) => (
                      <li key={index}>
                        <ButtonText
                          hover={this.state.orderType === f}
                          onClick={() => this.setState({ orderType: f })}
                        >
                          {f}
                        </ButtonText>
                      </li>
                    ))}
                  />
                </Container>

                <Container pb={[2, 5]}>
                  <AuctionsExhibitionsList
                    data={filteredData}
                    parent={parent}
                  />
                </Container>
              </React.Fragment>
            );
          }}
        />
      )
    );
  }
}

const generateMenu = viewItems => {
  let menu = ['All'];

  viewItems.map(item => {
    return item.auction_exhibition_type.map(type => menu.push(type.name));
  });

  return [...new Set(menu)];
};

PageExhibitionsAuctions.fragments = {
  entry: gql`
    fragment Taxonomy on ItemTaxonomy {
      id
      name
      slug
    }
  `,
};

const AUCTIONS_AND_EXHIBITIONS_QUERY = gql`
  query viewItems($postType: String, $per_page: Int, $order: String) {
    viewItems(postType: $postType, per_page: $per_page, order: $order) {
      title
      content
      acf
      seo
      slug
      type
      excerpt
      featured_media {
        link
        source_url
        media_details {
          sizes
        }
      }
      date {
        ...Taxonomy
      }
      auction_exhibition_type {
        ...Taxonomy
      }
    }
  }
  ${PageExhibitionsAuctions.fragments.entry}
`;
